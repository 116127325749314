import MarionetteError from '../error';

// Placeholder method to be extended by the user.
// The method should define the object that stores the behaviors.
// i.e.
//
// ```js
// Marionette.Behaviors.behaviorsLookup: function() {
//   return App.Behaviors
// }
// ```
export default function behaviorsLookup() {
  throw new MarionetteError({
    message: 'You must define where your behaviors are stored.',
    url: 'marionette.behaviors.md#behaviorslookup'
  });
}
